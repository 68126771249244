<template>
    <div>
        <div class="zhanwei"></div>
         <div class="all-main">
            <el-card>
                <div v-html="contetn"></div>
            </el-card>
            <div class="button-all">
                <el-button class="buitton-back" @click="backClick" type="primary">返回</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import {getset} from '@/api/index'
export default {
    data(){
        return{
            contetn:'',
        }
    },
    created(){
        let type = this.$route.query.type
        this.gettxtDetail(type)
    },
    methods:{
        gettxtDetail(type){
            getset({name:type}).then(res=>{
                this.contetn = res.data
            })
        },
        backClick(){
            this.$router.go(-1)
        }
    }
}
</script>

<style scoped>
.zhanwei{
    height: 120px;
}
.all-main{
    width: 70%;
    margin: auto;
    margin-bottom: 30px;
}
.button-all{
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;
}
.buitton-back{
    width: 400px;
    font-size: 30px;
}
</style>